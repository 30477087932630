import React from 'react';
import { Button, ButtonProps, Popover, PopoverProps } from '@blueprintjs/core';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { GovlyTableColumnVisibilityMenu } from '@/app/molecules/GovlyTable/GovlyTableColumnVisibilityMenu';

export type GovlyTableColumnVisibilityTogglesProps = {
  buttonProps?: ButtonProps;
  popoverProps?: PopoverProps;
};

export const GovlyTableColumnVisibilityPopover = ({
  buttonProps,
  popoverProps
}: GovlyTableColumnVisibilityTogglesProps) => {
  const { table } = useGovlyTableContext();

  const hasHideableColumns = table.getAllColumns().some(c => c.getCanHide());
  if (!hasHideableColumns) return null;

  return (
    <Popover content={<GovlyTableColumnVisibilityMenu />} {...popoverProps}>
      <Button outlined icon="eye-open" {...buttonProps} rightIcon="chevron-down">
        {buttonProps?.children ?? 'Columns'}
      </Button>
    </Popover>
  );
};
