import React, { useMemo } from 'react';
import { match } from 'ts-pattern';
import { CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { useGetQuotesForWorkspaceQuery } from '@/api/quotesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { QuoteShow } from '@/types/__generated__/GovlyApi';
import { OppWorkspaceQuoteArtifactItem } from '@/app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteArtifactItem';

type Artifact =
  | {
      type: 'quote';
      quote: QuoteShow;
    }
  | {
      type: 'summary';
    };

export const OppWorkspaceArtifactsCardListItem = ({
  workspaceId,
  cardListItemProps
}: {
  workspaceId: string;
  cardListItemProps: CardListItemProps;
}) => {
  const { data: quotes, isLoading } = useGetQuotesForWorkspaceQuery({ workspaceId });

  const artifacts = useMemo<Artifact[]>(() => {
    const quoteArtifacts = quotes?.map((quote): Extract<Artifact, { type: 'quote' }> => {
      return {
        type: 'quote',
        quote
      };
    });
    return [...(quoteArtifacts ?? [])];
  }, [quotes]);

  if (artifacts.length === 0) return null;

  return (
    <CardListItem title="Artifacts" {...cardListItemProps}>
      {isLoading ? (
        <Loading type="flex-row" />
      ) : (
        <dl className="flex flex-col gap-y-2 col-span-2">
          {artifacts.map(artifact =>
            match(artifact)
              .with({ type: 'quote' }, ({ quote }) => (
                <OppWorkspaceQuoteArtifactItem key={`quote-${quote.id}`} quote={quote} />
              ))
              .otherwise(() => null)
          )}
        </dl>
      )}
    </CardListItem>
  );
};
