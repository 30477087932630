import React from 'react';

import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { ContactsCard } from '@/app/molecules/ContactsCard/ContactsCard';
import { LocationsCard } from '@/app/molecules/LocationsCard/LocationsCard';
import { OppDetailsCardList } from '@/app/organisms/OppDetails/OppDetails';
import { OppDetailsActions } from '@/app/organisms/OppDetailsActions/OppDetailsActions';
import { useGetOppQuery } from '@/api/oppsApi';
import { QuestionsAndAnswers } from '@/app/molecules/QuestionsAndAnswers/QuestionsAndAnswers';
import { NotFound } from '@/app/molecules/NotFound/NotFound';
import { CardList } from '@/app/molecules/CardList/CardList';
import { SimilarOppsCard } from '@/app/molecules/SimilarOppsCard/SimilarOppsCard';
import { OppDetailsAttributesCard } from '@/app/organisms/OppDetailsAttributesCard/OppDetailsAttributesCard';

export const OppDetailsOverviewContent = ({ id, inDrawer }: { id: string; inDrawer?: boolean }) => {
  const { data } = useGetOppQuery({ id: id ?? '' }, { skip: !id });

  if (!id) return <NotFound />;

  const { latestData, contacts = [], recordLocations = [] } = data || {};
  const questions = latestData?.questions || [];

  return (
    <>
      <MainColumn className="lg:hidden" data-test="opp-details-mobile">
        <OppDetailsActions id={id} showDrawerButtons={inDrawer} />
        <OppDetailsCardList id={id}>
          <ContactsCard contacts={contacts} />
          <LocationsCard locations={recordLocations} />
          <QuestionsAndAnswers questions={questions} />
          <OppDetailsAttributesCard id={id} collapsible={false} />
          <SimilarOppsCard id={id} inDrawer={inDrawer} />
        </OppDetailsCardList>
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block" data-test="opp-details-left-col">
        <OppDetailsCardList id={id}>
          <ContactsCard contacts={contacts} />
          <LocationsCard locations={recordLocations} />
          <QuestionsAndAnswers questions={questions} />
        </OppDetailsCardList>
      </MainColumn>

      <MainColumn columnSpan={4} className="hidden lg:block" data-test="opp-details-right-col">
        <OppDetailsActions id={id} showDrawerButtons={inDrawer} />

        <CardList>
          <OppDetailsAttributesCard id={id} collapsible={false} />
          <SimilarOppsCard id={id} inDrawer={inDrawer} />
        </CardList>
      </MainColumn>
    </>
  );
};
