import React from 'react';
import { Menu, MenuItem, MenuProps } from '@blueprintjs/core';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { useEventTracking } from '@/app/hooks/useEventTracking';

type Props = Partial<MenuProps>;

export const GovlyTableColumnVisibilityMenu = (menuProps: Props) => {
  const { table, id } = useGovlyTableContext();
  const { trackEvent } = useEventTracking();

  const hasHideableColumns = table.getAllColumns().some(c => c.getCanHide());
  if (!hasHideableColumns) return null;

  return (
    <Menu {...menuProps}>
      {table.getAllColumns().map(c =>
        c.getCanHide() ? (
          <MenuItem
            key={c.id}
            roleStructure="listoption"
            text={c.columnDef.header?.toString()}
            selected={c.getIsVisible()}
            onClick={() => {
              c.toggleVisibility();
              trackEvent({
                object: id,
                action: 'toggle_column_visibility',
                properties: { column: c.columnDef.header }
              });
            }}
            shouldDismissPopover={false}
          />
        ) : null
      )}
    </Menu>
  );
};
