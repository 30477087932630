import React from 'react';
import { Text } from '@blueprintjs/core';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { CardListItem } from '@/app/molecules/CardList/CardList';
import { QuoteShow as Quote } from '@/types/__generated__/GovlyApi';
import { useOppWorkspaceQuoteListItem } from '@/app/organisms/OppWorkspaceQuoteForm/useOppWorkspaceQuoteListItem';
import { OppWorkspaceQuoteMenu } from '@/app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteMenu';

type OppWorkspaceQuoteListItemProps = {
  quote: Quote;
};

export function OppWorkspaceQuoteListItem({ quote }: OppWorkspaceQuoteListItemProps) {
  const { title, subtext, disableLineItems, QuoteIcon, viewQuote } = useOppWorkspaceQuoteListItem(quote);

  return (
    <CardListItem className="flex justify-between items-center">
      <div className="flex gap-x-2">
        <QuoteIcon />

        <div>
          <LinkTag tag="button" className="font-bold truncate max-w-64" onClick={viewQuote} disabled={disableLineItems}>
            {title}
          </LinkTag>
          <Text>{subtext}</Text>
        </div>
      </div>

      <OppWorkspaceQuoteMenu quote={quote} />
    </CardListItem>
  );
}
